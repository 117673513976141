<!---------------------------- TEMPLATE ----------->
<template>
  <div class="container" v-if="event">
    <h1>{{ event.title }}</h1>
    <p>{{ event.date }}</p>
    <p>{{ event.description }}</p>
  </div>
</template>
<!----------------------------- SCRIPTS ---------->
<script>
// import axios from 'axios';
import EventService from "@/services/EventService.js";
export default {
  name: "EventDetails",
  props: ["id"],
  data() {
    return {
      event: null,
    };
  },
  created() {
    EventService.getEvent(this.id)
      .then((res) => {
        this.event = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<!----------------------------- STYLES ---------->
<style scoped></style>
